import { convertDateToFirestoreTimestamp,
         convertDateStringToFirestoreTimestamp, 
         convertMapToFirestoreTimestamp,
         convertFirestoreTimestampToDate,
         convertFirestoreTimestampToDateString} from './FirestoreUtilities'
import { OrderUtilitiesError } from './errorMessages';
import CustomerUtilities from './CustomerUtilities';
import DataUtilities from './DataUtilities';
import PricingModelUtilities from './PricingModelUtilities';
import moment from 'moment';

export const performDefaultSortForOrders = (orders) => {
  return orders.sort((orderA, orderB) => {
    if (orderA.customer !== orderB.customer) {
      return orderA.customer < orderB.customer ? -1 : 1
    }
    const streetNameA = orderA.billToAddress.substr(orderA.billToAddress.indexOf(' ')+1)
    const streetNameB = orderB.billToAddress.substr(orderB.billToAddress.indexOf(' ')+1)
    if (streetNameA !== streetNameB) {
      return streetNameA < streetNameB ? -1 : 1
    }
    const streetNumberA = orderA.billToAddress.substr(0,orderA.billToAddress.indexOf(' '))
    const streetNumberB = orderB.billToAddress.substr(0,orderB.billToAddress.indexOf(' '))
    if (streetNumberA !== streetNumberB) {
      return streetNumberA < streetNumberB ? -1 : 1
    }
    return 0
  })  
}

export const performSortForReadyOrders = (orders) => {
  return orders.sort((orderA, orderB) => {
    if (orderA.city.trim() !== orderB.city.trim()) {
      return orderA.city.trim() < orderB.city.trim() ? -1 : 1
    }
    if (orderA.subdivision.trim() !== orderB.subdivision.trim()) {
      return orderA.subdivision.trim() < orderB.subdivision.trim() ? -1 : 1
    }
    if (orderA.orderDate.getTime() !== orderB.orderDate.getTime()) {
      return orderA.orderDate.getTime() < orderB.orderDate.getTime() ? -1 : 1
    }
    const streetNameA = orderA.deliveryAddress.substr(orderA.deliveryAddress.indexOf(' ')+1)
    const streetNameB = orderB.deliveryAddress.substr(orderB.deliveryAddress.indexOf(' ')+1)
    if (streetNameA.trim() !== streetNameB.trim()) {
      return streetNameA.trim() < streetNameB.trim() ? -1 : 1
    }
    const streetNumberA = orderA.deliveryAddress.substr(0,orderA.deliveryAddress.indexOf(' '))
    const streetNumberB = orderB.deliveryAddress.substr(0,orderB.deliveryAddress.indexOf(' '))
    if (streetNumberA.trim() !== streetNumberB.trim()) {
      return streetNumberA.trim()< streetNumberB.trim() ? -1 : 1
    }
    return 0
  })  
}

export const performSortForDispatchedOrders = (orders) => {
  return orders.sort((orderA, orderB) => {
    const orderASeq = orderA.dispatchSequence ? orderA.dispatchSequence : 9999
    const orderBSeq = orderB.dispatchSequence ? orderB.dispatchSequence : 9999
    return orderASeq < orderBSeq ? -1 : 1
  })
}

export const performSortForReadyToInvoiceOrders = (firebase,orders) => {
  return orders.sort((orderA, orderB) => {
    if (orderA.customer !== orderB.customer) {
      return orderA.customer < orderB.customer ? -1 : 1  
    }
    const orderAOrderDate = convertFirestoreTimestampToDate(firebase,orderA.orderDate)
    const orderBOrderDate = convertFirestoreTimestampToDate(firebase,orderB.orderDate)
    if (orderAOrderDate.getTime() !== orderBOrderDate.getTime()) {
      return orderAOrderDate < orderBOrderDate ? -1 : 1  
    }
    if (orderA.po !== orderB.po) {
      return orderA.po < orderB.po ? -1 : 1
    }
    return orderA.deliveryAddress < orderB.deliveryAddress ? -1 : 1
  })
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export default class OrderUtilities {

  constructor(firebase, db, areaMap, customerRefs, citiesMap, subdivisionsMap, materialMap, purposeMap, prices) {
    this.firebase = firebase
    this.db = db
    this.areaMap = areaMap
    this.customerRefs = customerRefs
    this.customerMap = customerRefs.map(customerRef => {
      return customerRef.data()
    }).reduce((obj, item) => {
      obj[item.name] = item
      return obj
    }, {})
    this.citiesMap = citiesMap
    this.subdivisionsMap = subdivisionsMap
    this.materialMap = materialMap
    this.purposeMap = purposeMap
    this.customerUtilities = new CustomerUtilities(firebase, db, customerRefs)
    this.pricingModelUtilities = new PricingModelUtilities(prices)
    this.dataUtilities = new DataUtilities()
  }

  calcStatusForOrder(order) {

    if (order.cancelled) {
      return 'Cancelled'
    } 
    if (order.held) {
      return 'Held'
    } 
    if (order.invoice && order.invoice.length > 0) {
      return 'Invoiced'
    } 
    if (order.readyToInvoice) {
      return 'Ready To Invoice'
    }
    if (order.delivered) {
      return 'Delivered'
    }
    if (order.dispatched) {
      return 'Dispatched'
    }
    if (order.ready) {
      return 'Ready To Dispatch'
    }
    if (!order.orderDate) {
      return 'Future'
    }
    return 'Ordered'

  }

  getEmptyOrder() {
    
    let emptyOrder = {
      actualDeliveryDate: null,
      anticipatedDeliveryDate: null,
      area: '',
      billToAddress: '',
      cancelled: false,
      cancelledDate: null,
      city: '',
      contact: '',
      contactEmail: '',
      customer: '',
      delivered: false,
      deliveryAddress: '',
      deliveryLocation: '',
      deliveryNotes: '',
      preloaded: false,
      preloadedDate: null,
      dispatched: false,
      dispatchedDate: null,
      dispatchSequence: 9999,
      heavy: false,
      held: false,
      heldDate: null,
      invoice: '',
      invoiceNotes: '',
      keyToDriversPage: '',
      loads: 0,
      location: '',
      map: '',
      material: '',
      notes: '',
      orderDate: convertDateToFirestoreTimestamp(this.firebase, new Date()),
      orderReference: '',
      payrollLoads: 0,
      pit: '',
      po: '',
      price: 0,
      priority: false,
      purpose: '',
      ready: false,
      readyDate: null,
      readyToInvoice: false,
      readyToInvoiceDate: null,
      requestedDeliveryDate: null,
      subdivision: '',
      ticket: '',
      truck: '',
      driver: '',
    }
    return emptyOrder
  }

  validateOrder = (order) => {
    // order must have valid customer
    if (!this.customerMap[order.customer]) {
      throw new Error(OrderUtilitiesError.orderCustomerRequired)
    }
    // order must have bill to address
    if (!order.billToAddress) {
      throw new Error(OrderUtilitiesError.addressRequiredForNewOrder)
    }
    // order must have delivery address
    if (!order.deliveryAddress) {
      throw new Error(OrderUtilitiesError.deliveryAddressRequiredForNewOrder)
    }
    // order must have valid city
    if (!this.citiesMap[order.city]) {
      throw new Error(OrderUtilitiesError.validCityRequiredFornewOrder)
    }
    // order must have valid subdivision if specified
    if (order.subdivision) {
      if (!this.subdivisionsMap[order.subdivision]) {
        throw new Error(OrderUtilitiesError.validSubdivisionRequiredFornewOrder)
      }  
    }
    // order must have valid material
    if (!this.materialMap[order.material]) {
      throw new Error(OrderUtilitiesError.validMaterialRequiredFornewOrder)
    }
    // order must have number of loads
    if (!order.loads) {
      throw new Error(OrderUtilitiesError.orderMinimumLoads)
    }
    // order must have number of loads
    if (!order.price || (order.price < 0)) {
      throw new Error(OrderUtilitiesError.orderPriceRequiredForNewOrders)
    }
    // purpose is optional, but must be valid if supplied
    if (order.purpose && !(this.purposeMap[order.purpose])) {
      throw new Error(OrderUtilitiesError.orderPurposeIsNotValid)
    } 
    this.convertOrderDates(order)
    this.cleanStrings(order)
    this.convertToTitleCase(order)
    this.convertFloats(order)
    this.convertInts(order)
  }

  // we want all dates in firestore to be a firestore Timestamp
  // if date field contains a JSDate or a JSON representation of 
  // a Timestamp it must be converted
  convertOrderDates = (order) => {
    const dateProperties = this.getDateProperties()
    dateProperties.forEach(property => {
      order[property] = this.convertADate(order[property])
    })
  }

  convertFloats= (order) => {
    const floatProperties = this.getFloatProperties()
    floatProperties.forEach(property => {
      let originalValue = order[property]
      order[property] = parseFloat(order[property])
      if (isNaN(order[property])) {
        console.log(property + "=" + originalValue)
        throw new Error(OrderUtilitiesError.isNaN)
      }   
    })
  }

  convertInts= (order) => {
    const intProperties = this.getIntProperties()
    intProperties.forEach(property => {
      order[property] = parseInt(order[property])
    })
  }

  cleanStrings = (order) => {
    const stringProperties = this.getStringProperties()
    stringProperties.forEach(property => {
      if (order[property]) {
        order[property] = this.dataUtilities.removeWhitespace(order[property])
      }
    })
  }

  getDateProperties = () => {
    return [
      'actualDeliveryDate',
      'anticipatedDeliveryDate',
      'requestedDeliveryDate',
      'cancelledDate',
      'preloadedDate',
      'dispatchedDate',
      'heldDate',
      'orderDate',
      'readyDate',
      'readyToInvoiceDate'
    ]
  }

  getStringProperties = () => {
    return [
      'billToAddress',
      'city',
      'contact',
      'contactEmail',
      'customer',
      'deliveryAddress',
      'deliveryLocation',
      'deliveryNotes',
      'invoiceNotes',
      'location',
      'notes',
      'pit',
      'subdivision',
      'ticket',
      'driver',
    ]

  }

  getFloatProperties = () => {
    return [
      'loads',
      'payrollLoads',
      'price'
    ]

  }

  getIntProperties = () => {
    return [
      'dispatchSequence'
    ]
  }

  convertOrderForOrderTable = (orderRef) => {
    const dateProperties = this.getDateProperties()
    const convertedOrder = orderRef.data()
    dateProperties.forEach(property => {
      const tempTimestamp = convertedOrder[property]
      convertedOrder[property] = convertFirestoreTimestampToDate(this.firebase, tempTimestamp)
    })
    convertedOrder.status = this.calcStatusForOrder(convertedOrder)
    return {ref: orderRef.id, ...convertedOrder}
  }

  convertOrderForEmail = (order) => {
    const dateProperties = this.getDateProperties()
    const convertedOrder = {...order}
    dateProperties.forEach(property => {
      const tempTimestamp = convertedOrder[property]
      convertedOrder[property] = convertFirestoreTimestampToDateString(this.firebase, tempTimestamp)
    })
    return convertedOrder
  }

  convertADate(date) {
    if (!date) {
      return null
    }
    let isDate = (date instanceof Date || moment.isMoment(date))
    // JS date
    if (isDate) {
      return convertDateToFirestoreTimestamp(this.firebase, date)
    }
    // JSON representation of Firestore timestamp
    if (!((typeof date.seconds === 'undefined') && (typeof date.nanoseconds === 'undefined'))) {
      return convertMapToFirestoreTimestamp(this.firebase, date)
    }
    // must be date string
    return convertDateStringToFirestoreTimestamp(this.firebase, date)
  }

  convertToTitleCase = (order) => {
    if (order.contact) {
      if (order.contact !== order.contact.toUpperCase()) {
        order.contact = toTitleCase(order.contact)
      }
    }
    if (order.billToAddress) {
      order.billToAddress = toTitleCase(order.billToAddress)
    }
    if (order.deliveryAddress) {
      order.deliveryAddress = toTitleCase(order.deliveryAddress)
    }
  }

  addOrder = async (order) => {
    let newOrder = {...this.getEmptyOrder(), ...order}
    this.validateOrder(newOrder)
    newOrder.area = this.citiesMap[newOrder.city].area
    newOrder.orderReference = this.generateOrderRef()
    return this.db.collection('orders').add(newOrder)
  }

  addMultiLineOrder = async (customer, po, orderLines, isFuture) => {
    const orderReference = this.generateOrderRef()
    const batch = this.db.batch();
    if (this.customerMap[customer].requiresPO && !po) {
      throw new Error(OrderUtilitiesError.poRequiredForOrder) 
    }
    if ((!this.customerMap[customer].requiresPO) && po) {
      throw new Error(OrderUtilitiesError.poNotAllowedForOrder) 
    }
    orderLines.forEach(orderLine => {
      let newOrder = {...this.getEmptyOrder(), ...orderLine}
      if (isFuture) {
        newOrder.orderDate = null
      }
      newOrder.customer = customer
      newOrder.po = po ? po : ''
      if (!newOrder.deliveryAddress) {
        newOrder.deliveryAddress = newOrder.billToAddress
      }
      this.validateOrder(newOrder)
      newOrder.area = this.citiesMap[newOrder.city].area
      newOrder.orderReference = orderReference
      if ((newOrder.anticipatedDeliveryDate !== null) && (!isFuture)) {
        newOrder.ready = true
        newOrder.readyDate = new Date()
      } 
      const orderRef = this.db.collection("orders").doc();
      batch.set(orderRef, newOrder);
    })
    await batch.commit()
    return orderReference
  }

  updateOrder = async (id, data, recalcPrice) => {
    let newOrder = {...this.getEmptyOrder(), ...data}
    if (recalcPrice) {
      const customer = this.customerUtilities.getByName(newOrder.customer).data()
      const orderDate = convertFirestoreTimestampToDate(this.firebase, newOrder.orderDate)
      newOrder.price = this.pricingModelUtilities.getPrice(newOrder.loads, customer.pricingModel, newOrder.material, orderDate, newOrder.city)
    }
    if (!newOrder.contact) newOrder.contact = ''
    if (!newOrder.contactEmail) newOrder.contactEmail = ''
    this.validateOrder(newOrder)
    newOrder.area = this.citiesMap[newOrder.city].area
    await this.db.collection('orders').doc(id).set(newOrder).catch(error => {
      console.error("Error updating document: ", error);
    });
  }

  processInvoiceUpdates = async (updates) => {
    const orderToUpdateRef = await this.db.collection('orders').doc(updates.ref).get()
    if (!orderToUpdateRef.exists) {
      throw new Error(OrderUtilitiesError.orderNotFound)
    }
    const orderToUpdate = orderToUpdateRef.data()
    if (orderToUpdate.customer !== updates.customer) {
      throw new Error(OrderUtilitiesError.customerInUpdateDoesNotMatchOrder)
    }
    if (orderToUpdate.material !== updates.material) {
      throw new Error(OrderUtilitiesError.materialInUpdateDoesNotMatchOrder)
    }
    if (orderToUpdate.purpose !== updates.purpose) {
      throw new Error(OrderUtilitiesError.purposeInUpdateDoesNotMatchOrder)
    }
    orderToUpdate.invoice = updates.invoice
    await this.db.collection('orders').doc(updates.ref).set(orderToUpdate).catch(error => {
      throw new Error(OrderUtilitiesError.orderNotFound)
    });

  }

  cancelOrder = async (id,data) => {
    let cancelledOrder = {...this.getEmptyOrder(), ...data}
    cancelledOrder.cancelled = true
    cancelledOrder.cancelledDate = new Date()
    await this.updateOrder(id, cancelledOrder)
  }

  uncancelOrder = async (id,data) => {
    let cancelledOrder = {...this.getEmptyOrder(), ...data}
    cancelledOrder.cancelled = false
    cancelledOrder.cancelledDate = null
    await this.updateOrder(id, cancelledOrder)
  }

  holdOrder = async (id,data) => {
    let heldOrder = {...this.getEmptyOrder(), ...data}
    heldOrder.held = true
    heldOrder.heldDate = new Date()
    await this.updateOrder(id, heldOrder)
  }

  unholdOrder = async (id,data) => {
    let heldOrder = {...this.getEmptyOrder(), ...data}
    heldOrder.held = false
    heldOrder.heldDate = null
    await this.updateOrder(id, heldOrder)
  }

  moveOrderBackToReadyAfterDelivery = async (orderId, orderToUpdate) => {
    const order = {...orderToUpdate}
    order.preloaded = false;
    order.preloadedDate = null;
    order.dispatched = false;
    order.dispatchedDate = null;
    order.driver = ''
    order.delivered = false
    order.actualDeliveryDate = null
    order.deliveryLocation = ''
    order.deliveryNotes = ''
    order.ticket = ''
    order.payrollLoads = order.loads
    order.pit = ''
    order.readyToInvoice = false
    order.readyToInvoiceDate = null
    this.updateOrder(orderId, order)
  }

  deliverOrder = async (orderId, order, actualDeliveryDate, truck, deliveryLocation, deliveryAddress, deliveryNotes, ticket, pit, payrollLoads) => {
    const nowTimestamp = convertDateToFirestoreTimestamp(this.firebase, new Date())
    order.delivered = true
    let actualDelDate = nowTimestamp
    if (actualDeliveryDate) {
      actualDelDate = convertDateStringToFirestoreTimestamp(this.firebase, actualDeliveryDate)
    }
    order.actualDeliveryDate = actualDelDate
    order.deliveryAddress = deliveryAddress ? deliveryAddress : order.billToAddress
    order.deliveryLocation = deliveryLocation ? deliveryLocation : ''
    order.deliveryNotes = deliveryNotes ? deliveryNotes : ''
    order.ticket = ticket ? ticket : ''
    order.payrollLoads = payrollLoads ? payrollLoads : order.loads
    order.pit = pit ? pit : ''
    order.truck = truck
    if (!order.po) {
      order.readyToInvoice = true
      order.readyToInvoiceDate = nowTimestamp
    }
    this.validateOrder(order)
    let orderToUpdate = this.db.collection("orders").doc(orderId);
    // this must be done synchronously so check for PO completed works
    await orderToUpdate.update(order).catch(error => {
      console.error("Error updating document: ", error);
    })
    if (order.readyToInvoice) {
      return
    }
    if (order.po.trim().toUpperCase() === 'EPO' || 
        order.po.trim().toUpperCase() === 'NPO') {
        return
    }
    this.checkForCompletedPO(order.customer, order.po)
  }

  setDispatchedOrderToReady = async(id, order) => { 
    order.preloaded = false;
    order.preloadedDate = null;
    order.dispatched = false;
    order.dispatchedDate = null;
    order.driver = ''
    await this.updateOrder(id, order)
  }

  removeReadyToInvoice = async(id, order) => {
    order.readyToInvoice = false;
    order.readyToInvoiceDate = null;
    await this.updateOrder(id, order)
  }

  markFutureOrderReady = (order, requestedDeliveryDate, po, contact, contactEmail, loads) => {
    let modifiedOrder = {...order}
    if (order.ready) {
      throw new Error(OrderUtilitiesError.cantConvertReadyOrderToReady) 
    }
    if (order.dispatched) {
      throw new Error(OrderUtilitiesError.cantConvertDispatchedOrderToReady) 
    }
    const orderDate = new Date()
    modifiedOrder.orderDate = convertDateToFirestoreTimestamp(this.firebase, orderDate)
    const customer = this.customerUtilities.getByName(modifiedOrder.customer).data()
    modifiedOrder = this.updateNotesInFutureOrder(modifiedOrder, customer);
    modifiedOrder.price = this.pricingModelUtilities.getPrice(modifiedOrder.loads, customer.pricingModel, modifiedOrder.material, orderDate, modifiedOrder.city)
    modifiedOrder.requestedDeliveryDate = requestedDeliveryDate
    modifiedOrder.anticipatedDeliveryDate = convertDateToFirestoreTimestamp(this.firebase, new Date())
    modifiedOrder.po = po
    modifiedOrder.ready = true
    modifiedOrder.readyDate = new Date()
    modifiedOrder.contact = contact
    modifiedOrder.contactEmail = contactEmail
    if (loads) {
      modifiedOrder.loads = loads
    }
    this.convertOrderDates(modifiedOrder)
    this.cleanStrings(modifiedOrder)
    return modifiedOrder
  }

  setFutureOrderToReady = async (id, order, requestedDeliveryDate, po, contact, contactEmail, loads) => {
    await this.updateOrder(id, this.markFutureOrderReady(order, requestedDeliveryDate, po, contact, contactEmail, loads))
  }

  setOrderToFuture = async (id, order) => {
    if (order.delivered) {
      throw new Error(OrderUtilitiesError.cantConvertDeliveredOrderToFuture) 
    }
    if (order.dispatched) {
      order.dispatched = false
      order.dispatchedDate = null
    }
    if (order.preloaded) {
      order.preloaded = false
      order.preloadedDate = null
    }
    if (order.ready) {
      order.orderDate = null
      order.ready = false
      order.readyDate = null
    }
    await this.updateOrder(id, order)  
  }

  checkForCompletedPO = async (customer, po) => {
    let querySnapshot = await this.db.collection('orders')
      .where('po', '==', po)
      .where('customer', '==', customer)
      .get()
    const data = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }))
    let poComplete = true
    data.forEach(row => {
      if (row.cancelled === true) {
        return
      }
      if (row.delivered !== true) {
        poComplete = false
      }
    })
    if (poComplete) {
      this.markOrdersReadyToInvoice(data)
    }
  } 

  markOrdersReadyToInvoice = (orders) => {
    const newValues = {}
    newValues.readyToInvoice = true
    const nowTimestamp = convertDateToFirestoreTimestamp(this.firebase, new Date())
    newValues.readyToInvoiceDate = nowTimestamp
    const valuesToChange = {
      readyToInvoice: true,
      readyToInvoiceDate: nowTimestamp
    }
    orders.forEach(row => {
      this.db.collection("orders").doc(row.id).update(valuesToChange).catch(function(error) {
        // The document probably doesn't exist.
        console.error(`Error updating document ${row.id}: ${error}`);
      });    
    })
  }

  markOrderReadyToInvoice = (orderId, order) => {
    if (!order.delivered) {
      throw new Error(OrderUtilitiesError.orderMustBeDeliveredToBeInvoiced)
    }
    const newValues = {}
    newValues.readyToInvoice = true
    const nowTimestamp = convertDateToFirestoreTimestamp(this.firebase, new Date())
    newValues.readyToInvoiceDate = nowTimestamp
    const valuesToChange = {
      readyToInvoice: true,
      readyToInvoiceDate: nowTimestamp
    }
    this.db.collection("orders").doc(orderId).update(valuesToChange).catch(function(error) {
      // The document probably doesn't exist.
      console.error(`Error updating document ${orderId}: ${error}`);
    });    
  }

  findPricingForOrderDate = (pricingModelForCustomer, orderDate) => {

    // // sort ascending
    // const sortedModels = pricingModelsForCustomer.sort((first, second) => {
    //   const firstDate = this.firestoreUtilities.convertFirestoreTimestampToDate(first.effectiveDate)
    //   const secondDate = this.firestoreUtilities.convertFirestoreTimestampToDate(second.effectiveDate)
    //   return firstDate - secondDate
    // })

    // find latest effective date that is less than or equal order date
    let bestFit = pricingModelForCustomer.prices[0]
    for (let pricingModel of pricingModelForCustomer.prices) {
      if (pricingModel.effectiveDate > orderDate) {
        return bestFit
      }
      bestFit = pricingModel
    }
    return bestFit
  }

  findPricingForMaterialAndLoadVolume = (effectivePricingModel, orderMaterial, orderLoads) => {
    // pricingModel for material
    for (const materialPricesForMaterial of effectivePricingModel.materials) {
      if (materialPricesForMaterial.material.includes(orderMaterial)) {
        let bestFit = null
        for (const prices of materialPricesForMaterial.loads) {
          bestFit = prices
          if (prices.qty === orderLoads) {
            break
          }
        }
        if (!bestFit) return 0
        return bestFit.pricePerLoad * orderLoads
      }
    }
    return 0
  }

  splitOrder = async (orderFromOrderTable, loadsForNewLine) => {
    if (!loadsForNewLine) {
      throw new Error(OrderUtilitiesError.loadsForNewLineRequired)
    }
    if (isNaN(loadsForNewLine)) {
      throw new Error(OrderUtilitiesError.splitRequestNotNumeric)
    }
    if (orderFromOrderTable.loads === 0) {
      throw new Error(OrderUtilitiesError.notEnoughLoadsToSplit)
    }
    if (Number(loadsForNewLine) >= Number(orderFromOrderTable.loads)) {
      throw new Error(OrderUtilitiesError.splitRequestExceedsLoads)
    }
    const newLine = {...orderFromOrderTable}
    delete newLine.ref
    delete newLine.tableData
    newLine.loads = loadsForNewLine
    const updatedLine = {...orderFromOrderTable}
    delete updatedLine.ref
    delete updatedLine.tableData
    updatedLine.loads -= loadsForNewLine
    const orderRef1 = this.db.collection("orders").doc();
    const customer = this.customerUtilities.getByName(newLine.customer).data()
    newLine.price = this.pricingModelUtilities.getPrice(newLine.loads, customer.pricingModel, newLine.material, newLine.orderDate)
    updatedLine.price = this.pricingModelUtilities.getPrice(updatedLine.loads, customer.pricingModel, updatedLine.material, updatedLine.orderDate, updatedLine.city)
    const batch = this.db.batch();
    this.validateOrder(newLine)
    batch.set(orderRef1, newLine);
    const orderRef2 = this.db.collection("orders").doc(orderFromOrderTable.ref);
    this.validateOrder(updatedLine)
    batch.update(orderRef2, updatedLine);
    await batch.commit()
  }

  dispatch = async (orderId, orderData, driver, anticipatedDeliveryDate) => {
    const order = this.validateAndPrepareOrderForDispatch(orderData, driver, anticipatedDeliveryDate)
    const load = this.db.collection("orders").doc(orderId);
    await load.update(order).catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  
  }

  dispatchAsPreload = async (orderId, orderData, driver, anticipatedDeliveryDate) => {
    const order = this.validateAndPrepareOrderForDispatchAsPreload(orderData, driver, anticipatedDeliveryDate)
    const load = this.db.collection("orders").doc(orderId);
    await load.update(order).catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  
  }

  validateAndPrepareOrderForDispatch = (orderToPrep, driverName, anticipatedDeliveryDate) => {
    const order = {...orderToPrep}
    if (!(order.loads > 0)) {
      throw new Error(OrderUtilitiesError.dispatchLoadMinimumNotMet)
    }
    if (order.loads > 3) {
      throw new Error(OrderUtilitiesError.dispatchLoadMatchExceed)
    }
    if (!order.ready) {
      throw new Error(OrderUtilitiesError.orderNotReadyToDispatch)
    }
    if (!driverName) {
      throw new Error(OrderUtilitiesError.driverRequiredToDispatch)
    }
    if (!anticipatedDeliveryDate) {
      throw new Error(OrderUtilitiesError.anticipatedDeliveryDateRequiredToDispatch)
    }
    order.dispatched = true
    order.dispatchedDate = new Date()
    order.dispatchSequence = 9999
    order.driver = driverName
    order.anticipatedDeliveryDate = convertDateToFirestoreTimestamp(this.firebase, anticipatedDeliveryDate)
    this.convertOrderDates(order)
    this.cleanStrings(order)
    return order
  }

  validateAndPrepareOrderForDispatchAsPreload = (orderToPrep, driverName, anticipatedDeliveryDate) => {
    const order = {...orderToPrep}
    if (!(order.loads > 0)) {
      throw new Error(OrderUtilitiesError.dispatchLoadMinimumNotMet)
    }
    if (order.loads > 3) {
      throw new Error(OrderUtilitiesError.dispatchLoadMatchExceed)
    }
    if (!order.ready) {
      throw new Error(OrderUtilitiesError.orderNotReadyToDispatch)
    }
    if (!driverName) {
      throw new Error(OrderUtilitiesError.driverRequiredToDispatch)
    }
    if (!anticipatedDeliveryDate) {
      throw new Error(OrderUtilitiesError.anticipatedDeliveryDateRequiredToDispatch)
    }
    order.preloaded = true
    order.preloadedDate = new Date()
    order.dispatched = true
    order.dispatchedDate = new Date()
    order.dispatchSequence = 9999
    order.driver = driverName
    order.anticipatedDeliveryDate = convertDateToFirestoreTimestamp(this.firebase, anticipatedDeliveryDate)
    this.convertOrderDates(order)
    this.cleanStrings(order)
    return order
  }


  prepareBatchDispatchData = (ordersFromTableToDispatch, driver, anticipatedDeliveryDate) => {
    const orderDataForDispatch = []
    ordersFromTableToDispatch.forEach(orderFormTable => {
      orderDataForDispatch.push(this.validateAndPrepareOrderForDispatch(orderFormTable, driver.name, anticipatedDeliveryDate))
    })
    return orderDataForDispatch
  }

  batchDispatch = (ordersFromTableToDispatch, driver, batch, ignoreSequence) => {
    const anticipatedDeliveryDate = moment().add(1, 'days')
    const orderDataForDispatch = this.prepareBatchDispatchData(ordersFromTableToDispatch, driver, anticipatedDeliveryDate)
    orderDataForDispatch.forEach((orderData, index) => {
      const orderRef = this.db.collection("orders").doc(orderData.ref);
      delete orderData.ref
      delete orderData.tableData
      orderData.dispatchSequence = ignoreSequence ? 9999 : index + 1
      this.validateOrder(orderData)
      batch.update(orderRef, orderData);
    })
    return orderDataForDispatch
  }

  updateDispatchSequences = async (ordersFromTableToSequence) => {
    const batch = this.db.batch()
    ordersFromTableToSequence.forEach((orderData, index) => {
      this.validateOrder(orderData)
      const orderRef = this.db.collection("orders").doc(orderData.ref);
      delete orderData.ref
      delete orderData.tableData
      orderData.dispatchSequence = index + 1
      batch.update(orderRef, orderData);
    })
    await batch.commit()
  }

  getPreloadedOrdersForDateInTableFormat = async (preloadedDate) => {
    let querySnapshot = await this.db.collection('orders')
      .where('preloaded', '==', true)
      .where('preloadedDate', '==', convertDateToFirestoreTimestamp(this.firebase, preloadedDate))
      .get()
    const data = querySnapshot.docs.map(doc => {
      return this.convertOrderForOrderTable(doc)
    })
    return data
  }

  getPreloadedOrdersForDateInTableFormatForDriver = async (preloadedDate, driver) => {
    let querySnapshot = await this.db.collection('orders')
      .where('preloaded', '==', true)
      .where('preloadedDate', '==', convertDateToFirestoreTimestamp(this.firebase, preloadedDate))
      .where('driver', '==', driver.name)
      .get()
    const data = querySnapshot.docs.map(doc => {
      return this.convertOrderForOrderTable(doc)
    })
    return data
  }

  batchDispatchWithInstructions = async (ordersFromTableToDispatch, driver, pit, rowColorMap, ignoreSequence, preloads) => {
    let allLoads = preloads ? preloads : []
    const batch = this.db.batch();
    const orderDataForDispatch = this.batchDispatch(ordersFromTableToDispatch, driver, batch, ignoreSequence)
    allLoads = allLoads.concat(orderDataForDispatch)
    const ordersFormattedForEmail = allLoads.map(rawOrder => {
      return this.convertOrderForEmail(rawOrder)
    })
    let convertedMap = {};
    for (let [key,value] of rowColorMap) {
      if (key) {
        convertedMap[key] = value
      }
    }
    const nowTimestamp = convertDateToFirestoreTimestamp(this.firebase, new Date())
    const nowAsDate = convertFirestoreTimestampToDateString(this.firebase, nowTimestamp)
    const email = {
      sentDate: nowAsDate,
      sentTo: driver.email,
      rowColorMap: convertedMap,
      driver: driver,
      pit: pit,
      orders: ordersFormattedForEmail
    }
    var emailRef = this.db.collection("dispatchEmail").doc();
    batch.set(emailRef, email);
    await batch.commit()
  }

  generateOrderRef = () => {
    const ts = String(new Date().getTime())
    let out = ''
    for (let i = 0; i < ts.length; i += 2) {
        out += Number(ts.substr(i, 2)).toString(36);
    }
    return out
  }

  findPossibleDuplicate = async (customer, billToAddress, material) => {
    let querySnapshot = await this.db.collection('orders')
      .where('billToAddress', '==', billToAddress)
      .where('material', '==', material)
      .where('customer', '==', customer)
      .where('delivered', '==', false)
      .get()
    const data = querySnapshot.docs.map(doc => {
      return this.convertOrderForOrderTable(doc)
    })
    return data 
  }


  updateNotesInFutureOrder(order, customer) {
    let updatedOrder = order
    if (!customer.defaultNotes) {
      return updatedOrder
    }
    if (order.notes === '' && customer.defaultNotes) {
      updatedOrder.notes = customer.defaultNotes;
      return updatedOrder
    } 
    let tempNotes = updatedOrder.notes
    if (tempNotes.includes(customer.defaultNotes)) {
      return updatedOrder
    }
    tempNotes = tempNotes + '. ' + customer.defaultNotes
    updatedOrder.notes= tempNotes
    return updatedOrder
  }

}
