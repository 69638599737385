import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField } from '@material-ui/core'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Image from "material-ui-image";
const momentTz = require('moment-timezone');

DialogForDeliveryInformation.propTypes = {
    onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    rowColorMap: PropTypes.object.isRequired,
  }

  export default function DialogForDeliveryInformation(props) {

    const onClose = props.onClose

    const [deliveryImageUrl, setDeliveryImageUrl] = useState(undefined)
    const [pitTicketImageUrl, setPitTicketImageUrl] = useState(undefined)
    const [loadColor, setLoadColor] = useState(undefined)
    
    useEffect(async () => {
      const storage = getStorage();
      if (props.order.deliveryImagePath) {
        setDeliveryImageUrl(await getDownloadURL(ref(storage, `delivery/${props.order.deliveryImagePath}`)))
      } else {
        setDeliveryImageUrl(undefined)
      }
      if (props.order.pitTicketImagePath) {
        setPitTicketImageUrl(await getDownloadURL(ref(storage, `pittickets/${props.order.pitTicketImagePath}`)))
      } else {
        setPitTicketImageUrl(undefined)
      }
      setLoadColor(props.rowColorMap.get(props.order.purpose.toUpperCase()))
    }, [props.order] )

    return (
        <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
          <DialogTitle id="simple-dialog-title">Delivery Information</DialogTitle>
          <DialogContent style={{backgroundColor: loadColor }}>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={4}>
                <TextField
                  value={props.order.deliveryAddress} 
                  label="Delivery Address"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={props.order.deliveryLocation} 
                  label="Location"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={momentTz(props.order.actualDeliveryDate).tz("America/Chicago").format("M/D/YYYY h:mm:s A")} 
                  label="Delivery Date/Time"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={8}>
                <TextField
                  value={props.order.deliveryNotes} 
                  label="Notes"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={4}>
                <TextField
                  value={props.order.pit} 
                  label="Pit"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={props.order.ticket} 
                  label="Ticket"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={4}>
                { deliveryImageUrl &&
                  <Image src={deliveryImageUrl} />
                }
                { !deliveryImageUrl && 
                  <TextField
                    value={"No delivery picture available"} 
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                }
              </Grid>
              <Grid item xs={4}>
                { pitTicketImageUrl &&
                  <Image src={pitTicketImageUrl} />
                }
                { !pitTicketImageUrl && 
                  <TextField
                    value={"No pit ticket picture available"} 
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={props.onClose} >Close</Button>
          </DialogActions>
    
        </Dialog>
      )

  }